import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { TeamStateDropdown } from 'components/team-state-dropdown'
import { store } from 'store/index'
import { StatusCellVm } from './status-vm'
import { Team } from 'store/modules/teams'
import { CirclesThreePlus } from '@phosphor-icons/react'

export const StatusCell = (data: CellContext<Team, unknown>) => {
  const team = data.row.original

  // TODO: remove once the team visibility dropdown updates using the store
  const refetchTeam = React.useCallback(async () => {
    await store.teams.fetchOne(team.id, {}, { bypassCache: true })
  }, [team.id])

  const statusVm = React.useMemo(() => {
    if (!team) return null
    return new StatusCellVm(team)
  }, [team])

  if (!team.framework) return null

  let content
  if (team.isTemplate) {
    content = (
      <div className="text-gray-400 py-1 px-3 border rounded flex items-center gap-1">
        <CirclesThreePlus size={14} className="inline" /> Template
      </div>
    )
  } else {
    content = (
      <TeamStateDropdown
        orgName={team.org.name}
        state={team.state}
        frameworkId={parseInt(team.framework.id)}
        orgSlug={team.org.slug}
        teamSlug={team.slug}
        publicFrameworkUrl={team.framework.publicUrl}
        permissions={{
          allowAddSkills: statusVm?.canBeEditedByCurrentUser,
          allowPublishTeam: statusVm?.canBeEditedByCurrentUser,
          allowViewSettings: statusVm?.canBeEditedByCurrentUser,
        }}
        onChangeState={refetchTeam}
        orgIsPublic={team.org.publicFramework}
        userCanEdit={statusVm?.canBeEditedByCurrentUser}
      />
    )
  }

  return <div className="flex flex-row gap-x-3 items-center">{content}</div>
}
