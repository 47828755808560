import { store } from 'store/index'
import { Doc } from 'store/modules/docs'
import { Position } from 'store/modules/positions'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'
import clip from 'text-clipper'
import { trackEvent } from '../../services/event-tracker'
import { successToast } from '../../utils/success-toast'

export class TeamNavDrawerVm {
  constructor(
    public publicView: boolean,
    public setEditing: (value: boolean) => void,
    public setReadMore: (value: boolean) => void,
    public setContent: (value: string) => void,
    public editing: boolean,
    public readMore: boolean,
    public content: string,
    public team?: Team
  ) {}

  public eventName = '$track_team_nav_drawer_event'

  private TEAM_MEMBERS_TO_SHOW = 17

  get members(): User[] {
    if (!this.team) return []

    return this.team.users.slice(0, this.TEAM_MEMBERS_TO_SHOW)
  }

  get editors(): User[] {
    if (!this.team) return []

    return this.team.editors.slice(0, this.TEAM_MEMBERS_TO_SHOW)
  }

  get showOverflowMembers(): boolean {
    if (!this.team) return false

    return this.team.users.length > this.TEAM_MEMBERS_TO_SHOW
  }

  get showOverflowEditors(): boolean {
    if (!this.team) return false

    return this.team.editors.length > this.TEAM_MEMBERS_TO_SHOW
  }

  get overflowMembersLength(): number {
    if (!this.team) return 0

    return this.team.users.length - this.TEAM_MEMBERS_TO_SHOW
  }

  get overflowEditorsLength(): number {
    if (!this.team) return 0

    return this.team.editors.length - this.TEAM_MEMBERS_TO_SHOW
  }
}
