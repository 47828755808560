import { Team } from 'store/modules/teams'
import { store } from 'store/index'

export class StatusCellVm {
  constructor(private team: Team) {}

  get canBeEditedByCurrentUser() {
    return this.isCurrentUserAdminOrEditor
  }

  private get isCurrentUserAdminOrEditor() {
    return store.currentUser
      ? this.team.editors.includes(store.currentUser) ||
          store.currentUser.isAdmin
      : false
  }
}
