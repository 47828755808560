import * as React from 'react'
import { LockSimple } from '@phosphor-icons/react'
import { Banner, Button } from 'src/design-system'
import { store } from 'store/index'

export type UpgradeCellProps = {
  colspan?: number
}

export const UpgradeCell: React.VFC<UpgradeCellProps> = (props) => {
  const { colspan } = props

  const org = store.currentUser?.org

  const billingPath = `/orgs/${org?.slug}/billing/new`

  return (
    <td colSpan={colspan}>
      <Banner variant="highlight" className="text-pink-700 gap-x-3 rounded">
        <LockSimple weight="bold" />
        Upgrade your plan to unlock
        <Button
          as="a"
          data-turbo-action="advance"
          href={billingPath}
          className="bg-pink-600 text-white no-underline"
        >
          Explore pricing
        </Button>
      </Banner>
    </td>
  )
}
