import { ConfirmationDialogProps } from '../../design-system/components/molecules/ConfirmationDialog'
import { FrameworkService } from '../../services/framework-service'
import { InternalApiClient } from '../../services/internal-api-client'
import { errorToast } from '../../utils/error-toast'
import { successToast } from '../../utils/success-toast'
import { trackSecondaryNavButtonClick } from './tracker'
import { TTeam } from 'src/design-system'

export class TeamsSecondaryNavVm {
  constructor(public team: TTeam, private client: InternalApiClient) {}

  positionsPath = `/teams/${this.team.slug}`
  frameworksTemplatesPath = `/teams/${this.team.slug}/frameworks_templates`
  skillsPath = `/teams/${this.team.slug}/skills`
  frameworkPath = `/teams/${this.team.slug}/framework`
  peoplePath = `/teams/${this.team.slug}/memberships`
  readmePath = `/teams/${this.team.slug}/readme`
  openRolesPath = `/teams/${this.team.slug}/open_roles`
  settingsPath = `/teams/${this.team.slug}/edit`

  private service = new FrameworkService(this.client)

  publishFrameworkConfirmationProps = {
    title: 'Are you sure you want to publish this team?',
    body: 'This will make the team visible to everyone in your organization.',
    confirmLabel: 'Publish team',
  } satisfies ConfirmationDialogProps

  async onPublishTeamClick(frameworkId: number) {
    try {
      const result = await this.service.changeState({
        frameworkId,
        state: 'published',
      })
      successToast(result.notice)
      trackSecondaryNavButtonClick({
        button: 'Publish team',
        path: location.pathname,
      })
      return true
    } catch (e) {
      console.error(e)
      errorToast((e as Error).message)
      return false
    }
  }
}
