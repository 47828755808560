import * as React from 'react'
import { LoadingContent } from 'components/loading-content'
import { store } from 'store/index'
import { TeamsTableProvider } from './context/team-table'
import { TeamTable } from './table/table'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'
import * as SecondaryNav from 'components/secondary-nav'
import { observer } from 'mobx-react-lite'
import { TeamsPageFilters } from './filters'
import { Button } from 'src/design-system'

export const TeamsPage = observer(() => {
  const [loadingData, setLoadingData] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchTeams = async () => {
        await store.teams.fetchAll({
          page: { size: 999 },
          include: [
            'editors',
            'framework',
            'framework.frameworks_skills',
            'framework.frameworks_skills.skill',
            'framework.positions',
          ],
        })
      }

      const fetchComments = async () => {
        await store.comments.fetchAll({ page: { size: 9999 } })
      }

      await Promise.all([fetchTeams(), fetchComments()])

      setLoadingData(false)
    }

    fetchData()
  }, [])

  const { openNewTeamModal } = useOpenNewTeamModal()

  return (
    <LoadingContent loading={loadingData}>
      <TeamsTableProvider>
        <main className="relative w-full h-screen flex flex-col">
          <div className="w-full flex flex-col gap-3 pb-3">
            <SecondaryNav.Root>
              <SecondaryNav.Header className="flex flex-row items-center justify-between w-full">
                Teams
                {store.currentUser?.isAdmin && (
                  <SecondaryNav.Actions>
                    <Button
                      onClick={() => openNewTeamModal()}
                      variant="default"
                      className="items-center"
                      size="nav"
                    >
                      Create team
                    </Button>
                  </SecondaryNav.Actions>
                )}
              </SecondaryNav.Header>
            </SecondaryNav.Root>
            <TeamsPageFilters />
          </div>
          <TeamTable />
        </main>
      </TeamsTableProvider>
    </LoadingContent>
  )
})
