import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Avatar, Link } from 'src/design-system'
import { Team } from 'store/modules/teams'
import pluralize from 'pluralize'
import cn from 'classnames'

export const NameCell = (data: CellContext<Team, unknown>) => {
  const team = data.row.original

  const skillsCount = team.framework?.frameworksSkills.length
  const positionsCount = team.framework?.positions.length

  return (
    <Link
      className={cn(
        'flex flex-row gap-x-3 items-center min-w-[200px] p-0 text-gray-900 text-sm leading-5 no-underline',
        team.isLocked && 'opacity-40'
      )}
      href={`/teams/${team.slug}`}
      data-turbo-frame="content"
      data-turbo-action="advance"
    >
      <Avatar
        size="sm"
        shape="square"
        className="flex-shrink-0"
        fallbackText={team.name.charAt(0)}
        src={team.framework?.imageUrl}
        alt={`Avatar for ${team.name}`}
      />
      <div className="flex flex-col">
        <span className="font-bold">{team.name}</span>
        <span className="text-gray-500 leading-5 whitespace-nowrap truncate">
          {positionsCount} {pluralize('position', positionsCount)}
          <span className="mx-1.5">&middot;</span>
          {skillsCount} {pluralize('skill', skillsCount)}
        </span>
      </div>
    </Link>
  )
}
