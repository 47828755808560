import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Team } from 'store/modules/teams'
import { AvatarButtonGroup, Link } from 'src/design-system'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'

export const EditorsCell = (data: CellContext<Team, unknown>) => {
  const editors = data.cell.row.original?.editors

  const team = data.cell.row.original

  return (
    <div className="flex flex-row gap-x-1 justify-start items-center">
      <Link href={`${team.baseUrl}/editors`} underline={false}>
        <span className="sr-only">Manage editors</span>
        <AvatarButtonGroup
          avatars={avatarGroupProps(editors, {
            href: false,
            stateTooltip: false,
          })}
          className="mr-1 text-gray-500"
          size="xs"
          limitStyle="tag"
          limit={2}
          showEmptyState={true}
        />
      </Link>
    </div>
  )
}
