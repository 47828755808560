import { createColumnHelper } from '@tanstack/react-table'
import {
  NameCell,
  StatusCell,
  CommentsCell,
  EditorsCell,
  MembersCell,
  LastEditCell,
  ActionsCell,
} from './cells'

import { store } from 'store/index'
import { Team } from 'store/modules/teams'
export class TeamsData {
  get data(): Team[] {
    return [...store.teams.viewableForCurrentUser]
  }

  columnHelper = createColumnHelper<Team>()

  get columns() {
    return [
      this.columnHelper.accessor('name', {
        header: 'Name',
        cell: NameCell,
        id: 'name',
      }),
      this.columnHelper.accessor('state', {
        header: 'Status',
        cell: StatusCell,
        id: 'status',
      }),
      this.columnHelper.accessor('users', {
        header: 'Team Members',
        cell: MembersCell,
        id: 'members',
      }),
      this.columnHelper.accessor('editors', {
        header: 'Editors',
        cell: EditorsCell,
        id: 'editors',
      }),
      this.columnHelper.display({
        header: 'Comments',
        cell: CommentsCell,
        id: 'comments',
      }),
      this.columnHelper.accessor('updatedAt', {
        header: 'Last Edited',
        cell: LastEditCell,
        id: 'lastEdited',
      }),
      this.columnHelper.display({
        header: 'Actions',
        cell: ActionsCell,
        id: 'actions',
      }),
    ]
  }
}
