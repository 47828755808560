import { GlobalDrawer } from 'src/design-system'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { TeamNavDrawerVm } from './team-nav-drawer-vm'
import {
  TeamSecondaryNavPermissions,
  TeamSecondaryNavUrls,
} from 'components/teams-secondary-nav'
import { store } from 'store/index'
import { PeopleSection } from './people-section'
import { EditorsSection } from './editors-section'
import { X } from '@phosphor-icons/react'
import { useDrawerContext } from 'src/design-system'

export const TEAM_NAV_DRAWER_ID = 'team_nav_drawer'

export type TeamNavDrawerProps = {
  teamId: string
  urls: TeamSecondaryNavUrls
  permissions: TeamSecondaryNavPermissions
  publicView: boolean
}

export const TeamNavDrawer: React.VFC<TeamNavDrawerProps> = observer(
  (props) => {
    const { teamId, permissions, publicView } = props

    const drawerContext = useDrawerContext()

    const closeDrawer = () => {
      drawerContext?.closeDrawer()
    }

    const team = store.teams.byId(teamId.toString(), {
      useDefaultFilter: false,
    })

    const [readMore, setReadMore] = React.useState(false)
    const [editing, setEditing] = React.useState(false)
    const [content, setContent] = React.useState('')

    const vm = new TeamNavDrawerVm(
      publicView,
      setEditing,
      setReadMore,
      setContent,
      editing,
      readMore,
      content,
      team
    )

    return (
      <GlobalDrawer
        id={TEAM_NAV_DRAWER_ID}
        title="Team Navigation"
        overlay={false}
        className="shadow-none border-solid border-gray-100 border-l border-t-0 border-r-0 border-b-0 w-[290px] overflow-y-auto z-sideNav flex flex-col"
      >
        <div className="flex justify-between items-baseline px-6 pt-4">
          <h3 className="text-base mb-0">People</h3>
          <button
            onClick={() => closeDrawer()}
            className="z-10 bg-white rounded-full p-2 w-8 h-8 flex justify-center items-center border-px border-gray-50 border-solid"
          >
            <X className="h-4 text-gray-900 w-4" weight="bold" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="p-6">
          {permissions.allowViewPeople && !team?.template && (
            <PeopleSection
              allowInvite={permissions.allowInvite}
              allowAddToTeam={permissions.allowInvite}
              vm={vm}
            />
          )}

          {permissions.allowViewPeople && !team?.template && (
            <EditorsSection
              allowEditorAdd={permissions.allowViewSettings}
              vm={vm}
            />
          )}
        </div>
      </GlobalDrawer>
    )
  }
)
