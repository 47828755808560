import { Avatar, AvatarButton, Button, Tag } from 'src/design-system'
import * as React from 'react'
import { TeamNavDrawerVm } from './team-nav-drawer-vm'
import { WithAnalytics } from 'components/with-analytics'
import { openModal } from '../../utils/open-modal'

export type EditorsSectionProps = {
  allowEditorAdd?: boolean
  vm: TeamNavDrawerVm
}

export const EditorsSection: React.VFC<EditorsSectionProps> = (props) => {
  const { allowEditorAdd, vm } = props

  const openEditorModal = () => {
    openModal(`/teams/${vm.team?.slug}/editors/new`)
  }

  return (
    <>
      <h3 className="mb-4 font-bold text-sm ">
        <span className="pr-2">Editors</span>
        <Tag variant="naked" className="inline-block">
          {vm.editors.length}
        </Tag>
      </h3>
      <div className="mb-9">
        <div className="grid grid-cols-6 gap-y-2 mb-5">
          {vm.editors.length === 0 &&
            Array.from({ length: 6 }).map((_, i) => (
              <div key={i} className="rounded-full h-8 w-8 bg-gray-50"></div>
            ))}
          {vm.editors.map((user) => {
            return (
              <WithAnalytics
                event={vm.eventName}
                params={{
                  action: 'avatar-clicked',
                  userId: user.id,
                }}
                key={user.id}
              >
                <AvatarButton
                  key={user.id}
                  fallbackText={user.initials}
                  alt={user.fullName}
                  src={user.avatarUrl}
                  isInactive={user.state !== 'active'}
                  href={`/users/${user.slug}`}
                  size="sm"
                />
              </WithAnalytics>
            )
          })}
          {vm.showOverflowEditors && (
            <Avatar fallbackText={`+${vm.overflowEditorsLength}`} size="sm" />
          )}
        </div>
        {allowEditorAdd && (
          <Button fullWidth variant="outline" onClick={openEditorModal}>
            Add an editor
          </Button>
        )}
      </div>
    </>
  )
}
