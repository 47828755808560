import * as React from 'react'
import cn from 'classnames'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import styles from './EntityCard.module.scss'

export type DropdownProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'div'>
>

export const Dropdown: React.VFC<DropdownProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <div className={cn(styles.dropdown, className)} {...restProps}>
      {children}
    </div>
  )
}
