import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Team } from 'store/modules/teams'
import { CommentFrameworkCount } from 'components/comments/comment-framework-count'

export const CommentsCell = (data: CellContext<Team, unknown>) => {
  const commentsCount = data.cell.row.original?.framework?.comments?.length

  return <CommentFrameworkCount commentsCount={commentsCount} />
}
