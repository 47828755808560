import React from 'react'

export const useEmbedded = () => {
  const [isEmbedded, setIsEmbedded] = React.useState(false)

  React.useEffect(() => {
    try {
      setIsEmbedded(window.self !== window.top)
    } catch (e) {
      setIsEmbedded(true)
    }
  }, [])

  return isEmbedded
}
