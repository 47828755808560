import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { TeamsData } from '../table/data'
import { Team } from 'store/modules/teams'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

type TeamsTableContextProps = {
  globalFilter: string
  setGlobalFilter: (value: string) => void
  columnFilters: ColumnFiltersState
  setColumnFilters: (value: ColumnFiltersState) => void
  // Used internally for tracking events
  columnFiltersObject: Record<string, string>
  data: TeamsData
  table: Table<Team>
}

const TeamsTableContext = React.createContext<TeamsTableContextProps | null>(
  null
)

export const TeamsTableProvider = observer(
  ({
    children,
  }: {
    children:
      | React.ReactNode
      | ((ctx: TeamsTableContextProps) => React.ReactNode)
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const teamsData = React.useMemo(() => {
      return new TeamsData()
    }, [])

    const table = useReactTable({
      data: teamsData.data,
      columns: teamsData.columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: {
        columnFilters,
        globalFilter,
        columnVisibility: {
          comments: store.currentUser?.isAdmin || false,
          actions: store.currentUser?.isAdmin || false,
        },
      },
      initialState: {
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
    })

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      globalFilter,
      setGlobalFilter,
      table,
      data: teamsData,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <TeamsTableContext.Provider value={contextValue}>
        {content}
      </TeamsTableContext.Provider>
    )
  }
)

export const useTeamsTable = () => {
  const context = React.useContext(TeamsTableContext)
  if (context === null) {
    throw new Error('useTeamsTable must be used within a TeamsTableProvider')
  }
  return context
}
