import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTeamsTable } from '../context/team-table'
import * as Table from 'components/table'
import { flexRender } from '@tanstack/react-table'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'
import { Button } from 'src/design-system'
import { FolderDashed } from '@phosphor-icons/react'
import { store } from 'store/index'

export const TeamTable = observer(() => {
  const { table } = useTeamsTable()

  const source = 'teams-table'

  const { openNewTeamModal } = useOpenNewTeamModal()

  return (
    <div className="overflow-x-auto bg-gray-25 w-full flex-grow pb-24 border-0 border-t border-gray-100 border-solid">
      <Table.Root className="bg-white max-w-full shadow-xs">
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row
              key={headerGroup.id}
              className="bg-white sticky top-0 z-[3]"
              showBottomBorder={false}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Header
                    key={header.id}
                    header={header}
                    source={source}
                    stickyHeader={true}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.Header>
                )
              })}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body>
          {table.getRowModel().rows.length === 0 && (
            <Table.NoResultsRow colspan={table.getAllColumns().length}>
              <div className="flex flex-col items-center gap-3">
                <FolderDashed className="fill-gray-300" size={24} />
                No teams found
                {store.currentUser?.isAdmin && (
                  <Button
                    onClick={() => openNewTeamModal()}
                    variant="default"
                    className="items-center"
                    size="nav"
                  >
                    Create a team
                  </Button>
                )}
              </div>
            </Table.NoResultsRow>
          )}

          {table.getRowModel().rows.map((row, index) => {
            const isLocked = row.original.isLocked

            const cells = isLocked
              ? row.getVisibleCells().slice(0, 1)
              : row.getVisibleCells()

            return (
              <Table.Row showBottomBorder={true} key={row.id}>
                {cells.map((cell) => (
                  <Table.Cell key={cell.id} isLocked={isLocked}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
                {isLocked && (
                  <Table.UpgradeCell
                    colspan={table.getAllColumns().length - 1}
                  />
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </div>
  )
})
