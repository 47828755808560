import { GlobalConfirmationDialog, TTeam } from 'src/design-system'
import { SubscriptionState } from 'components/subscription-banner/subscription-banner'
import * as React from 'react'
import { FrameworkState } from '../../types/entities'
import { TeamsSecondaryNav } from './teams-secondary-nav'

export type TeamSecondaryNavPermissions = {
  allowViewSettings?: boolean
  allowPublishTeam?: boolean
  allowAddSkills?: boolean
  allowViewPeople?: boolean
  allowViewReadme?: boolean
  allowViewOpenRoles?: boolean
  allowBrowseSkills?: boolean
  allowInvite?: boolean
  allowAddOpenRole?: boolean
  allowEditReadme?: boolean
}

export type TeamSecondaryNavUrls = {
  addNewPosition: string
}

export type RailsTeamsSecondaryNavProps = {
  team: TTeam
  template?: boolean
  frameworkId: number
  publicFrameworkUrl: string
  state: FrameworkState
  backPath?: string
  permissions: TeamSecondaryNavPermissions
  orgAvatar?: string
  orgName?: string
  orgSlug: string
  orgIsPublic: boolean
  teamSlug?: string
  readmeContent?: string | null
  readmeTitle: string
  urls: TeamSecondaryNavUrls
  children?: React.ReactNode
  source: string
  subscriptionState: SubscriptionState
  trialEndsAt: number
}

export const RailsTeamsSecondaryNav: React.VFC<RailsTeamsSecondaryNavProps> = (
  props
) => (
  <>
    <GlobalConfirmationDialog id="team-secondary-nav-publish" />
    <TeamsSecondaryNav {...props} />
  </>
)
