import { ChatTeardropText } from '@phosphor-icons/react'
import * as React from 'react'
import { LoadingContent } from 'components/loading-content'

export type CommentFrameworkCountProps = {
  commentsCount?: number
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const CommentFrameworkCount: React.VFC<CommentFrameworkCountProps> = ({
  commentsCount,
  onClick,
}) => {
  return (
    <LoadingContent
      className="h-4 w-4"
      loading={!commentsCount && commentsCount !== 0}
    >
      <button type="button" className="flex items-center" onClick={onClick}>
        <ChatTeardropText className="text-gray-500 w-4 h-4" aria-hidden />
        <span className="text-xs text-gray-900 pl-1">{commentsCount}</span>
        <span className="sr-only">Comments</span>
      </button>
    </LoadingContent>
  )
}
