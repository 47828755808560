import { trackEvent } from '../../services/event-tracker'

type NavLink = {
  title: string
  href?: string
}

export const eventName = '$track_team_secondary_nav_click'

export const trackSecondaryNavClick = (link: NavLink) => {
  trackEvent(eventName, { link })
}

export const trackSecondaryNavButtonClick = (
  params: Record<string, unknown>
) => {
  trackEvent(eventName, params)
}
