import { InternalApiClient, InternalApiError } from './internal-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'

export class TeamService {
  constructor(private client: InternalApiClient) {}

  async copy(props: { teamId: number }) {
    const { teamId } = props

    try {
      await this.client.post('/teams', {
        team: {
          clone_from: teamId,
        },
      })
      successToast('Team successfully copied')
      location.assign(location.pathname)
      return true
    } catch (e) {
      console.error(e)
      const error = e as InternalApiError
      const errorMessage = error?.body?.error as string | undefined
      errorMessage ? errorToast(errorMessage) : errorToast()
      return false
    }
  }

  async delete(props: { teamId: number }) {
    const { teamId } = props

    try {
      await this.client.delete(`/teams/${teamId}`)
      successToast('Team successfully deleted')
      location.assign(location.pathname)
      return true
    } catch (e) {
      errorToast()
      return false
    }
  }
}
