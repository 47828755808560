import {
  Avatar,
  AvatarButton,
  Button,
  ModalButton,
  Tag,
} from 'src/design-system'
import * as React from 'react'
import { INVITE_MODAL_ID } from 'components/invite-modal/utils'
import { TeamNavDrawerVm } from './team-nav-drawer-vm'
import { WithAnalytics } from 'components/with-analytics'
import { useAddUsersToTeamModal } from 'components/add-users-to-team-modal/utils'
import { AddUsersToTeamModal } from 'components/add-users-to-team-modal'

export type PeopleSectionProps = {
  allowInvite?: boolean
  allowAddToTeam?: boolean
  vm: TeamNavDrawerVm
}

export const PeopleSection: React.VFC<PeopleSectionProps> = (props) => {
  const { allowInvite, allowAddToTeam, vm } = props

  const { openAddUsersToTeamModal } = useAddUsersToTeamModal()

  const addOrInvite = allowAddToTeam || allowInvite

  return (
    <>
      <h3 className="mb-4 font-bold text-sm ">
        <span className="pr-2">Team members</span>
        <Tag variant="naked" className="inline-block opacity-25">
          {vm.team?.users.length}
        </Tag>
      </h3>
      <div className="mb-8">
        <div className="grid grid-cols-6 gap-y-2 mb-5">
          {vm.team?.users.length === 0 &&
            Array.from({ length: 6 }).map((_, i) => (
              <div key={i} className="rounded-full h-8 w-8 bg-gray-50"></div>
            ))}
          {vm.members.map((user) => {
            return (
              <WithAnalytics
                event={vm.eventName}
                params={{
                  action: 'avatar-clicked',
                  userId: user.id,
                }}
                key={user.id}
              >
                <AvatarButton
                  key={user.id}
                  fallbackText={user.initials}
                  alt={user.fullName}
                  src={user.avatarUrl}
                  isInactive={user.state !== 'active'}
                  href={`/users/${user.slug}`}
                  size="sm"
                />
              </WithAnalytics>
            )
          })}
          {vm.showOverflowMembers && (
            <Avatar fallbackText={`+${vm.overflowMembersLength}`} size="sm" />
          )}
        </div>
        {addOrInvite && (
          <>
            <div className="flex flex-row items-center justify-between gap-x-3 w-full">
              <WithAnalytics
                event={vm.eventName}
                params={{ action: 'invite-teammate' }}
              >
                <ModalButton
                  modalId={INVITE_MODAL_ID}
                  modalProps={{ intent: 'team_nav_drawer' }}
                  variant="outline"
                  fullWidth
                >
                  Invite new
                </ModalButton>
              </WithAnalytics>
              <Button
                variant="outline"
                onClick={() => vm.team && openAddUsersToTeamModal(vm.team)}
                fullWidth
              >
                Move existing
              </Button>
            </div>
            <AddUsersToTeamModal />
          </>
        )}
      </div>
    </>
  )
}
