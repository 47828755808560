import cn from 'classnames'
import * as React from 'react'
import styles from './ItemWithCount.module.scss'
import {
  PropsWithRequiredChildren,
  PropsWithoutChildren,
} from '../../../types/helpers'
import { Count, CountProps } from '../../atoms/Count'

export type ItemWithCountProps = {
  count?: number
  countProps?: PropsWithoutChildren<CountProps>
} & PropsWithRequiredChildren<React.ComponentPropsWithoutRef<'div'>>

export const ItemWithCount: React.VFC<ItemWithCountProps> = (props) => {
  const { count = 0, className, countProps, children, ...restProps } = props

  return (
    <div className={cn(styles.itemWithCount, className)} {...restProps}>
      {children}
      <Count {...countProps}>{count}</Count>
    </div>
  )
}
