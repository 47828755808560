import { forRails } from 'components/for-rails'
import { TeamCard } from '../../packs/src/components/team-card'
import { RailsTeamsSecondaryNav } from '../src/components/teams-secondary-nav'
import { TeamStateDropdown } from '../src/components/team-state-dropdown'
import { registerComponents } from '../src/utils/react/registry'
import { TeamsPage } from 'pages/teams-page'

registerComponents({
  TeamCard: forRails(TeamCard),
  TeamsSecondaryNav: forRails(RailsTeamsSecondaryNav),
  TeamStateDropdown: forRails(TeamStateDropdown),
  TeamsPage: forRails(TeamsPage),
})
