import {
  AvatarButtonGroup,
  AvatarButtonGroupProps,
  EntityCard,
  Icon as DSIcon,
  ItemWithCount,
  OverflowMenu,
  Tag,
  TTeam,
} from 'src/design-system'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { PersonOutline } from '@easy-eva-icons/react'
import * as React from 'react'
import { FrameworkState } from '../types/entities'
import { TeamService } from '../services/team-service'
import { trackEvent } from '../services/event-tracker'
import { useApiClient } from '../utils/use-api-client'
import {
  Buildings,
  CircleDashed,
  CircleHalf,
  Globe,
  Icon,
  Lock,
} from '@phosphor-icons/react'
import cn from 'classnames'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { openModal } from '../utils/open-modal'
import { ColourVariantWithTheme } from '../design-system/types/colour-variant'

type StatusOption = {
  title: string
  variant: ColourVariantWithTheme
  icon: Icon
  iconClassName: string
}

export type TeamCardProps = {
  team: TTeam
  state: FrameworkState
  imageUrl?: string
  locked: boolean
  permissions: {
    allowUpdateTeam?: boolean
    allowDeleteTeam?: boolean
    allowShowPeople?: boolean
    allowShowStatus?: boolean
    allowShowHiring?: boolean
  }
}

export const TeamCard: React.VFC<TeamCardProps> = observer((props) => {
  const { team, state, locked, permissions, imageUrl, ...restProps } = props

  const showConvertToTemplate =
    store.currentUser?.org?.activeSubscription && store.currentUser?.isAdmin

  const positionsPath = `/teams/${team.slug}`
  const frameworkPath = `/teams/${team.slug}/framework`
  const settingsPath = `/teams/${team.slug}/edit`

  const client = useApiClient()
  const service = React.useMemo(() => new TeamService(client), [client])

  const onCopyTeam = async () => {
    trackEvent('$track_copy_team_clicked')

    await service.copy({ teamId: team.id })
  }

  const onDeleteTeam = async () => {
    if (
      window.confirm(
        `Are you absolutely sure? This will permanently delete this team.`
      )
    ) {
      await service.delete({ teamId: team.id })
    }
  }

  const onConvertToTemplate = () => {
    trackEvent('$track_convert_team_to_template_clicked')
    openModal(`/templates/new?team_to_clone_id=${team.id}`)
  }

  const Header = () => (
    <EntityCard.Header className="mb-2">
      <EntityCard.Title className="self-center">{team.name}</EntityCard.Title>
      {permissions.allowUpdateTeam && (
        <EntityCard.Dropdown
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Menu />
        </EntityCard.Dropdown>
      )}
    </EntityCard.Header>
  )

  const Menu = () => (
    <OverflowMenu.Root right>
      <OverflowMenu.Link className="text-gray-900" href={positionsPath}>
        Positions
      </OverflowMenu.Link>
      <OverflowMenu.Link className="text-gray-900" href={frameworkPath}>
        Framework
      </OverflowMenu.Link>
      <OverflowMenu.Link className="text-gray-900" href={settingsPath}>
        Settings
      </OverflowMenu.Link>
      {!locked && (
        <OverflowMenu.Button onClick={onCopyTeam}>
          Make a copy
        </OverflowMenu.Button>
      )}
      {showConvertToTemplate && (
        <OverflowMenu.Button onClick={onConvertToTemplate}>
          Convert to template
        </OverflowMenu.Button>
      )}
      {permissions.allowDeleteTeam && (
        <OverflowMenu.Button variant="destructive" onClick={onDeleteTeam}>
          Delete
        </OverflowMenu.Button>
      )}
    </OverflowMenu.Root>
  )

  const Body = () => {
    if (!permissions.allowShowPeople) {
      return null
    }

    if (team.activeUsers?.length) {
      const avatars: AvatarButtonGroupProps['avatars'] = team.activeUsers.map(
        (user) => ({
          fallbackText: user.fname[0] + user.lname?.[0],
          identifier: user.id.toString(),
          tooltipContent: `${user.fname} ${user.lname}`,
          src: user.avatarUrl,
          alt: `Avatar for ${user.fname} ${user.lname}`,
        })
      )

      return (
        <EntityCard.Body className="h-8">
          <AvatarButtonGroup limit={5} avatars={avatars} />
        </EntityCard.Body>
      )
    }

    return (
      <EntityCard.Body className="h-8">
        <ItemWithCount count={0}>
          <DSIcon hideBorder variant="grey">
            <PersonOutline />
          </DSIcon>
        </ItemWithCount>
      </EntityCard.Body>
    )
  }

  const Footer = () => {
    return (
      <EntityCard.Footer>
        <ItemWithCount count={team.positionCount}>Positions</ItemWithCount>
        <EntityCard.Separator />
        <ItemWithCount count={team.skillCount}>Team Skills</ItemWithCount>
      </EntityCard.Footer>
    )
  }

  const statusOptions: Record<FrameworkState, StatusOption> = {
    in_progress: {
      title: 'In Progress',
      variant: 'paper' as ColourVariantWithTheme,
      icon: CircleDashed,
      iconClassName: 'text-gray-900',
    },
    in_review: {
      title: 'In Review',
      variant: 'orange' as ColourVariantWithTheme,
      icon: CircleHalf,
      iconClassName: 'text-orange-500',
    },
    published: {
      title: 'Live',
      variant: 'success' as ColourVariantWithTheme,
      icon: Buildings,
      iconClassName: 'text-green-600',
    },
    publicly_accessible: {
      title: 'Live (Public)',
      variant: 'success' as ColourVariantWithTheme,
      icon: Globe,
      iconClassName: 'text-green-600',
    },
  }

  return (
    <EntityCard.Root
      className={cn('min-h-[138px] m-0 h-full', locked && '')}
      href={positionsPath}
      data-turbo-frame="content"
      data-turbo-action="advance"
      {...restProps}
    >
      <div className="relative w-full">
        {[permissions.allowShowStatus, permissions.allowShowHiring].some(
          Boolean
        ) && (
          <div className="absolute top-2 left-2 flex flex-row items-center gap-x-2">
            {permissions.allowShowStatus && (
              <Tag
                variant={statusOptions[state].variant}
                className="py-0.5 px-1.5"
              >
                {statusOptions[state].title}
              </Tag>
            )}
            {permissions.allowShowHiring && <Tag variant="warning">Hiring</Tag>}
            {locked && (
              <Tag variant="highlight" className="bg-opacity-100">
                <Lock className="mr-1" weight="bold" /> Upgrade to unlock
              </Tag>
            )}
          </div>
        )}
        {imageUrl && (
          <LazyLoadImage
            className="rounded-sm mb-4 h-28 object-cover w-full"
            src={imageUrl}
          />
        )}
      </div>
      <Header />
      <Body />
      <Footer />
    </EntityCard.Root>
  )
})
