import { SearchFilter } from 'components/search-filter'
import * as React from 'react'
import { useTeamsTable } from '../context/team-table'

export const TeamsPageFilters = () => {
  const { globalFilter, setGlobalFilter, table } = useTeamsTable()

  return (
    <div className="flex w-full flex-wrap justify-between items-center gap-3 px-6">
      <div className="flex flex-row gap-2 flex-wrap">
        <SearchFilter
          name="org-teams-search"
          onChange={(value) => {
            setGlobalFilter(String(value))
          }}
          value={globalFilter ?? ''}
        />
      </div>
      <div className="text-gray-600">
        Showing{' '}
        <strong>{table.getRowModel().rows.length.toLocaleString()}</strong> of{' '}
        <strong>
          {table.getFilteredRowModel().rows.length.toLocaleString()}
        </strong>{' '}
        teams
      </div>
    </div>
  )
}
