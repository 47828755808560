import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Team } from 'store/modules/teams'
import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import { Copy, GearSix, SelectionBackground } from '@phosphor-icons/react'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { store } from 'store/index'
import { openModal } from 'app/packs/src/utils/open-modal'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'

export const ActionsCell = (data: CellContext<Team, unknown>) => {
  const team = data.cell.row.original

  const { openNewTeamModal } = useOpenNewTeamModal()

  const onDestroy = React.useCallback(async () => {
    const result = await store.teams.byId(team.id)?.destroy()

    if (result?.success) {
      successToast('Team deleted!')
    } else {
      errorToast()
    }
  }, [team])

  const onCopyTeam = async () => {
    const result = await store.teams.create({
      name: `Copy of ${team.name}`,
      clonedFrom: team.id || undefined,
    })

    if (result?.success) {
      trackEvent('$track_copy_team_clicked', {
        source: 'build-hub',
      })
      successToast('Team copied!')
    } else {
      errorToast()
    }
  }

  const onConvertToTemplate = React.useCallback(() => {
    trackEvent('$track_convert_team_to_template_clicked')
    openModal(`/templates/new?team_to_clone_id=${team.id}`)
  }, [team.id])

  const showConvertToTemplate =
    store.currentUser?.org?.activeSubscription &&
    store.currentUser?.isAdmin &&
    !team.isTemplate

  const showMakeACopy = store.currentUser?.isAdmin && !team.isTemplate

  const showMakeTeamFromTemplate = store.currentUser?.isAdmin && team.isTemplate

  return (
    <ConfirmationDialog.Root
      onConfirm={onDestroy}
      title="Are you sure?"
      body="This cannot be undone."
    >
      <div className="flex flex-col">
        <OverflowMenu.Root right>
          {showMakeACopy && (
            <OverflowMenu.Button onClick={onCopyTeam}>
              <span className="flex flex-row items-center gap-x-1 text-gray-900">
                <Copy
                  aria-hidden
                  className="text-gray-600 w-3.5 h-3.5"
                  weight="bold"
                />
                Make a copy
              </span>
            </OverflowMenu.Button>
          )}

          {showConvertToTemplate && (
            <OverflowMenu.Button onClick={onConvertToTemplate}>
              <div className="flex flex-row items-center gap-x-1 text-gray-900">
                <SelectionBackground
                  aria-hidden
                  className="text-gray-600 w-3.5 h-3.5"
                  weight="bold"
                />
                Convert to template
              </div>
            </OverflowMenu.Button>
          )}
          {showMakeTeamFromTemplate && (
            <OverflowMenu.Button
              className="flex flex-row items-center gap-x-1 text-gray-900"
              onClick={() => openNewTeamModal(team.id)}
            >
              <SelectionBackground
                aria-hidden
                className="text-gray-600 w-3.5 h-3.5"
                weight="bold"
              />
              Create team from template
            </OverflowMenu.Button>
          )}
          <OverflowMenu.Link
            href={`/teams/${team.slug}/edit`}
            className="flex flex-row items-center gap-x-1 text-gray-900"
          >
            <GearSix
              aria-hidden
              className="text-gray-600 w-3.5 h-3.5"
              weight="bold"
            />
            {team.isTemplate ? 'Template' : 'Team'} settings
          </OverflowMenu.Link>

          <ConfirmationDialog.Trigger asChild>
            <OverflowMenu.Button variant="destructive">
              <div className="flex flex-row items-center gap-x-1 text-red-600">
                Delete
              </div>
            </OverflowMenu.Button>
          </ConfirmationDialog.Trigger>
        </OverflowMenu.Root>
      </div>
    </ConfirmationDialog.Root>
  )
}
