import {
  AlignCenterVertical,
  ChartBarHorizontal,
  CirclesThreePlus,
  GearSix,
} from '@phosphor-icons/react'
import {
  Avatar,
  AvatarButtonGroup,
  Button,
  Link,
  useDrawerContext,
  Tag,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { eventName } from './tracker'
import { RailsTeamsSecondaryNavProps } from '.'
import { store } from 'store/index'
import { SubscriptionBanner } from 'components/subscription-banner/subscription-banner'
import { TeamNavDrawer, TEAM_NAV_DRAWER_ID } from 'components/team-nav-drawer'
import { TeamsSecondaryNavVm } from './vm'
import { TeamStateDropdown } from 'components/team-state-dropdown'
import { useApiClient } from '../../utils/use-api-client'
import { useEmbedded } from '../../utils/use-embedded'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'
import { useRailsContext } from '../rails-context'
import { WithAnalytics } from 'components/with-analytics'
import * as SecondaryNav from 'components/secondary-nav'
import ProgressionLogo from '../../../images/powered-by-progression.svg'
import { avatarGroupProps } from '../../utils/user-helpers'

export const TeamsSecondaryNav = observer(
  (props: RailsTeamsSecondaryNavProps) => {
    const {
      request: { fullpath },
      currentUser,
    } = useRailsContext()

    const {
      backPath,
      frameworkId,
      orgIsPublic,
      orgAvatar,
      orgName,
      orgSlug,
      permissions,
      publicFrameworkUrl,
      state,
      team,
      readmeContent,
      urls,
      source,
      subscriptionState,
      trialEndsAt,
      template,

      // unused props
      teamSlug, // eslint-disable-line @typescript-eslint/no-unused-vars
      readmeTitle, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...restProps
    } = props

    const { toggleDrawer } = useDrawerContext()

    const { openNewTeamModal } = useOpenNewTeamModal()

    React.useEffect(() => {
      async function fetchTeamDocs() {
        await store.teams.fetchOne(team.id.toString(), {
          include: [
            'cloned_from_template',
            'docs',
            'framework',
            'open_positions',
            'users',
          ],
        })
      }
      fetchTeamDocs()
    }, [team.id])

    const [status, setStatus] = React.useState(state)
    const {
      request: { subdomain },
    } = useRailsContext()
    const embedded = useEmbedded()
    const client = useApiClient()
    const publicView = !currentUser
    const vm = new TeamsSecondaryNavVm(team, client)

    const storeTeam = store.teams.byId(team.id.toString())

    React.useEffect(() => {
      storeTeam && setStatus(storeTeam.state)
    }, [storeTeam, storeTeam?.state])

    const showInfoButton = React.useMemo(() => {
      if (permissions.allowViewPeople) return true
      if (!storeTeam) return false

      const hasOpenPositions = storeTeam.openPositions.length > 0

      if (currentUser?.orgRole === 'admin') return true
      if (!publicView) return true

      if (permissions.allowViewReadme && readmeContent) return true
      if (permissions.allowViewOpenRoles && hasOpenPositions) return true
    }, [
      storeTeam,
      readmeContent,
      publicView,
      currentUser,
      permissions.allowViewReadme,
      permissions.allowViewOpenRoles,
      permissions.allowViewPeople,
    ])

    const backLinkPath =
      backPath || subdomain === 'app' ? `/orgs/${orgSlug}` : '/'

    const showTabs =
      status === 'published' ||
      status === 'publicly_accessible' ||
      permissions.allowPublishTeam

    React.useEffect(() => {
      if (location.search.includes('open_drawer')) {
        // if we've opened the skill drawer, we can remove the query param
        // so that it doesn't open again on refresh or browser navigation
        window.history.replaceState(
          window.history.state,
          '',
          window.location.pathname
        )
      }
    }, [])

    const [visibilityDropdownDefaultOpen, setVisibilityDropdownDefaultOpen] =
      React.useState(false)

    React.useEffect(() => {
      if (location.search.includes('open_visibility_dropdown')) {
        window.history.replaceState(
          window.history.state,
          '',
          window.location.pathname
        )

        setTimeout(() => {
          setVisibilityDropdownDefaultOpen(true)
        }, 100)
      }
    }, [])

    if (embedded) return null

    const userAdminEditor =
      currentUser?.orgRole === 'admin' ||
      storeTeam?.hasEditor(currentUser?.id.toString())

    return (
      <>
        <SecondaryNav.Root className="flex items-center" {...restProps}>
          <SecondaryNav.Header className="gap-1 font-normal pr-2">
            {orgName && (
              <>
                <a
                  href={backLinkPath}
                  className="flex items-center gap-1.5 text-gray-900"
                >
                  <Avatar
                    src={orgAvatar}
                    fallbackText={orgName.slice(0, 2)}
                    alt={`Avatar for ${orgName}`}
                    className=""
                    size="xs"
                  />
                  {orgName}
                </a>
                <span className="text-gray-300">/</span>
              </>
            )}
            {!publicView && userAdminEditor && (
              <>
                <Link
                  href="/teams"
                  underline={false}
                  className="text-gray-900 font-normal p-0"
                  data-turbo-frame="content"
                  data-turbo-action="advance"
                >
                  Teams
                </Link>
                <span className="text-gray-300">/</span>
              </>
            )}
            <span className="font-normal">{team.name}</span>
            {template && <Tag variant="info">Template</Tag>}
          </SecondaryNav.Header>
          <SecondaryNav.Body className="flex-grow">
            {showTabs && (
              <div className="w-full flex justify-between">
                <SecondaryNav.Tabs clickEventName={eventName}>
                  <SecondaryNav.Tab
                    className="items-center gap-x-0.5 flex"
                    href={vm.positionsPath}
                    exact
                    data-element-id="positions-tab"
                  >
                    <ChartBarHorizontal className="w-4 h-4 text-gray-600" />
                    Positions
                  </SecondaryNav.Tab>
                  <SecondaryNav.Tab
                    className="items-center gap-x-0.5 flex"
                    href={vm.frameworkPath}
                    data-element-id="framework-tab"
                  >
                    <CirclesThreePlus className="w-4 h-4 text-gray-600" />
                    Framework
                  </SecondaryNav.Tab>
                  <SecondaryNav.Tab
                    className="items-center gap-x-0.5 flex"
                    href={vm.skillsPath}
                    data-element-id="skills-tab"
                  >
                    <AlignCenterVertical className="w-4 h-4 text-gray-600" />
                    Skills
                  </SecondaryNav.Tab>
                </SecondaryNav.Tabs>
              </div>
            )}
            {currentUser && !template && permissions.allowPublishTeam && (
              <TeamStateDropdown
                defaultOpen={visibilityDropdownDefaultOpen}
                state={status}
                orgName={orgName}
                orgIsPublic={orgIsPublic}
                orgSlug={orgSlug}
                onChangeState={setStatus}
                teamSlug={team.slug}
                frameworkId={frameworkId}
                publicFrameworkUrl={publicFrameworkUrl}
                permissions={permissions}
              />
            )}
          </SecondaryNav.Body>
          <SecondaryNav.Actions>
            {permissions.allowViewPeople && !template && (
              <AvatarButtonGroup
                avatars={avatarGroupProps(storeTeam?.users || [], {
                  stateTooltip: true,
                })}
                className="mx-1.5 cursor-pointer"
                limit={3}
                limitClassName="bg-gray-100 font-normal text-gray-900"
                limitStyle="avatar"
                size="xs"
                showEmptyState={true}
                onClick={() => toggleDrawer(TEAM_NAV_DRAWER_ID)}
              />
            )}
            {permissions.allowViewSettings && !template && (
              <a
                href={storeTeam?.settingsPath}
                className="flex-shrink-0 p-2 flex items-center"
              >
                <span className="sr-only">Settings</span>
                <GearSix weight="bold" className="w-4 h-4 text-gray-400" />
              </a>
            )}
            {permissions.allowBrowseSkills && !template && (
              <WithAnalytics
                key="browseTemplates"
                event={eventName}
                params={{ button: 'Browse templates', path: fullpath }}
              >
                <Button
                  variant="outline"
                  size="sm"
                  as="a"
                  className="self-center"
                  data-turbo-action="advance"
                  href={vm.frameworksTemplatesPath}
                >
                  Browse templates
                </Button>
              </WithAnalytics>
            )}
            {template && (
              <WithAnalytics
                event={eventName}
                params={{ button: 'Use template' }}
              >
                <Button
                  type="button"
                  variant="default"
                  onClick={() => {
                    openNewTeamModal(team.id.toString())
                  }}
                >
                  Create team with this template
                </Button>
              </WithAnalytics>
            )}
            {publicView && (
              <a
                className={cn(showInfoButton && 'mr-6')}
                href={`https://progressionapp.com?utm_source=public_framework&utm_medium=web&utm_campaign=org-${orgSlug}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={ProgressionLogo} alt="Progression" className="h-7" />
              </a>
            )}
            <TeamNavDrawer
              teamId={team.id.toString()}
              urls={urls}
              permissions={permissions}
              publicView
            />
          </SecondaryNav.Actions>
        </SecondaryNav.Root>
        {!publicView && (
          <SubscriptionBanner
            subscriptionState={subscriptionState}
            source={source}
            trialEndsAt={trialEndsAt}
          />
        )}
      </>
    )
  }
)
